import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Drawer } from '../Drawer';
import { Icon } from '../Icon';
import { NavbarLink, NavigationBar, NavbarMenu } from '../NavigationBar';
import { themeV1 } from '../theme';
import useCheckMobileScreen from '../CustomHooks/useCheckMobileScreen';
import { useOutsideClick } from '../CustomHooks/useOutsideClick';
import { Box } from '../Box';

export interface SubContent {
  key: number | string;
  title: string;
  // Visibility of the item
  isVisible: boolean;
  // only to pass Link from react-router-dom
  customComponent: React.ReactNode;
}

// To pass the content to navigation
export interface ContentValues {
  key: number | string;
  title: string;
  hrefLink?: string;
  // to display start icon
  primaryIcon?: React.ReactNode;
  // For Web view, this positioning of the navigation contents. Right or Left
  position: 'left' | 'right';
  // Currently selected nav item.
  isSelected?: boolean;
  // Visibility of the item
  isVisible: boolean;
  // type of the navigation item must be one of the below, if type left unassigned it's considered as NavItem with Icon.
  type?: 'link' | 'button' | 'menu' | 'custom';
  callback?: () => void;
  // only to pass Link from react-router-dom
  customComponent?: React.ReactNode;
  subItems?: SubContent[];
}

interface NavigationDrawerProps {
  logo?: string | React.ReactNode | React.ReactNode[];
  // to pass the navlink to logo
  logoHref?: string;
  // Use it to pass the navigation contents for both web and mobile view
  navigationContent: ContentValues[];
}

interface WrapperProps {
  mobileView?: boolean;
  isOpen?: boolean;
  active?: boolean;
}

const BorderLine = styled('div')(() => ({
  border: `1px solid ${themeV1.colors.separators.primary[3]}`,
  margin: `0 ${themeV1.space[2]}`,
}));

const WrapperShared = styled('div')(({ mobileView, active } : WrapperProps) => ({
  position: 'relative',
  '&:hover': {
    'svg path': {
      fill: themeV1.colors.base.body[2],
    },
  },
  '&::after': active && {
    display: 'block',
    content: '""',
    height: mobileView ? 34 : 4,
    borderLeft : mobileView ? `5px solid ${themeV1.colors.base.white}` : 'initial',
    background: !mobileView ? themeV1.colors.base.white : 'transparent',
    position: 'absolute',
    left: 0,
    top: mobileView ? 7 : 38,
  },
}));


const Wrapper = styled(WrapperShared)(({ mobileView, active } : WrapperProps) => ({
  display: mobileView ? 'flex' : 'block',
  '&::after': active && {
    width: 'calc(100% + 2px)'
  },
}));

const WrapperWithIcon = styled(WrapperShared)(({ mobileView, active } : WrapperProps) => ({
  display: mobileView ? 'flex' : 'block',
  '&::after': active && {
    width: 'calc(100% + 8px)'
  },
}));

const WrapperMenu = styled(WrapperShared)(({ mobileView, active } : WrapperProps) => ({
  display: 'block',
  '&::after': active && {
    width: mobileView ? 'auto' : 'calc(100% + 2px)'
  },
}));

const CustomBox = styled(Box)(() => ({
  color: themeV1.colors.base.white,
  gap: 6,
  '&:hover': {
    color: themeV1.colors.base.body[2],
  },
  'svg:hover path': {
    fill: themeV1.colors.base.body[2],
  },
}));

const Menu = styled(Box)(({ mobileView, isOpen } : WrapperProps) => ({
  color: isOpen ? themeV1.colors.base.body[2] : themeV1.colors.base.white,
  cursor: 'pointer',
  gap: 6,
  marginLeft: mobileView ? themeV1.space[1] : 0,
  '&:hover': {
    color: themeV1.colors.base.body[2],
    marginLeft: mobileView ? themeV1.space[1] : 0,
  },
  'svg:hover path': {
    fill: themeV1.colors.base.body[2],
  }
}));

export const NavigationDrawer = ({ logo, logoHref, navigationContent }: NavigationDrawerProps) => {
  const isMobile = useCheckMobileScreen();
  const [isOpen, setIsOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuClick = () => {
    setOpenMenu(false);
  };
  const ref = useOutsideClick(handleMenuClick);

  const checkWithType = (item: ContentValues) => {
    switch (item.type) {
      case 'link':
        return (
          <Wrapper active={item.isSelected} mobileView={isMobile}>
            <NavbarLink href={item.hrefLink || '#'} startIcon={isMobile ? item.primaryIcon : <></>}>
              {item.title}
            </NavbarLink>
          </Wrapper>
        );
      case 'custom':
        return (
          <Wrapper active={item.isSelected} mobileView={isMobile}>
            <CustomBox align="center" orientation="horizontal">
              {isMobile && item.primaryIcon}
              {item.customComponent}
            </CustomBox>
          </Wrapper>
        );
      case 'button':
        return (
          <WrapperWithIcon active={item.isSelected} mobileView={isMobile}>
            <NavbarLink href="#" startIcon={item.primaryIcon} onClick={item.callback}>
              {item.title}
            </NavbarLink>
          </WrapperWithIcon>
        );
      case 'menu':
        return (
          <WrapperMenu active={item.isSelected} mobileView={isMobile}>
            <div ref={ref} onClick={() => setOpenMenu(!openMenu)}>
              <Menu isOpen={openMenu} align="center" orientation="horizontal">
                {isMobile && item.primaryIcon}
                {item.title}
              </Menu>
            </div>
            {openMenu && <NavbarMenu items={item.subItems} />}
          </WrapperMenu>
        );
      default:
        return (
          <WrapperWithIcon active={item.isSelected} mobileView={isMobile}>
            <NavbarLink href={item.hrefLink || '#'} startIcon={item.primaryIcon}>
              {item.title}
            </NavbarLink>
          </WrapperWithIcon>
        );
    }
  };

  const displayContent = (position: 'left' | 'right') =>
    navigationContent.map(
      (item) => item.position === position && item.isVisible && checkWithType(item)
    );

  const webNavBar = (
    <NavigationBar>
      <NavigationBar.Left>
        <NavbarLink href={logoHref || '/'}>{logo}</NavbarLink>
        {displayContent('left')}
      </NavigationBar.Left>
      <NavigationBar.Right>{displayContent('right')}</NavigationBar.Right>
    </NavigationBar>
  );

  const mobileNavBar = (
    <NavigationBar>
      <NavigationBar.Left>
        <NavbarLink href={logoHref || '/'}>{logo}</NavbarLink>
      </NavigationBar.Left>
      <NavigationBar.Right>
        <Icon.Medium.Menu fill={themeV1.colors.base.white} onClick={() => setIsOpen(true)} />
      </NavigationBar.Right>
    </NavigationBar>
  );

  return (
    <div>
      {isMobile ? mobileNavBar : webNavBar}
      {isMobile && (
        <Drawer
          open={isOpen}
          onClose={() => setIsOpen(false)}
          backgroundColor={themeV1.colors.fills[6]}
        >
          {displayContent('left')}
          <BorderLine />
          {displayContent('right')}
        </Drawer>
      )}
    </div>
  );
};
