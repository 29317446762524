import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useOutsideClick } from '../CustomHooks/useOutsideClick';
import { Icon } from '../Icon';
import { themeV1 } from '../theme';
import { Box } from '../Box';
interface DrawerProps {
  children: string | React.ReactNode | React.ReactNode[];
  open: boolean;
  anchor?: 'left' | 'right';
  onClose: () => void;
  closeIconVisibility?: boolean;
  backgroundColor?: string;
}

const DrawerContainer = styled(Box)(
  ({
    open = false,
    anchor = 'right',
    backgroundColor,
  }: Omit<DrawerProps, 'children' | 'onClose'>) => ({
    position: 'fixed',
    top: 0,
    backgroundColor: backgroundColor || themeV1.colors.base.white,
    boxShadow: themeV1.shadow.base,
    zIndex: 99,
    gap: themeV1.space[1],
    transform: 'translateX(100%)',
    animation: open ? 'slide-in 0.5s forwards' : 'slide-out 0.5s forwards',
    ...(anchor === 'right'
      ? {
          right: 0,
        }
      : {
          left: 0,
        }),
    svg: {
      alignSelf: 'flex-end',
      margin: 14,
      cursor: 'pointer',
    },
    '@keyframes slide-in': {
      '100%': { transform: 'translateX(0%)' },
      '0%': { transform: 'translateX(100%)' },
    },
    '@keyframes slide-out': {
      '0%': { transform: 'translateX(0%)' },
      '100%': { transform: 'translateX(100%)' },
    },
  })
);

export const Drawer = ({
  children,
  open,
  anchor,
  onClose,
  closeIconVisibility = true,
  backgroundColor,
}: DrawerProps) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleHeaderClick = () => {
    setIsOpen(false);
    onClose();
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const ref = useOutsideClick(handleHeaderClick);
  return (
    <div ref={ref}>
      <DrawerContainer
        minHeight="100%"
        minWidth="300px"
        align="stretch"
        orientation="vertical"
        anchor={anchor}
        open={isOpen}
        backgroundColor={backgroundColor}
      >
        {closeIconVisibility && (
          <Icon.Medium.Close fill={themeV1.colors.base.white} onClick={onClose} />
        )}
        {children}
      </DrawerContainer>
    </div>
  );
};
