import styled from '@emotion/styled';
import React from 'react';
import { themeV1 } from '../theme';
import { BaseText } from './BaseText';

const BaseDisplay = styled(BaseText)(({ size }: { size: 'h1' | 'h2' | 'h3' }) => {
  switch (size) {
    case 'h1':
      return {
        fontWeight: '700',
        fontSize: '4.25em',
      };
    case 'h2':
      return {
        fontWeight: '800',
        fontSize: '2em',
      };
    case 'h3':
      return {
        fontFamily: themeV1.font.fontFamily.inter,
        fontWeight: '700',
        fontSize: '2.8125',
      };
  }
});

export const Display = ({
  children,
  size,
}: {
  children: string | React.ReactNode;
  size: 'h1' | 'h2' | 'h3';
}) => (
  <h1>
    <BaseDisplay size={size}>{children}</BaseDisplay>
  </h1>
);
