import React from 'react';
import { themeV1 } from '../theme';

// TODO: Get this to automatically generate from the Icons svg folder
export const User: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
React.createElement('path', {
  ...args,
  fill: args.fill ?? themeV1.colors.fills[4],
  d: 'M11.9996 12.5714C15.4711 12.5714 18.2853 9.75722 18.2853 6.28571C18.2853 2.81421 15.4711 0 11.9996 0C8.52808 0 5.71387 2.81421 5.71387 6.28571C5.71387 9.75722 8.52808 12.5714 11.9996 12.5714Z M12 14.8572C8.68223 14.9481 5.43099 15.8088 2.50286 17.3715C1.74937 17.7538 1.11658 18.3375 0.674764 19.0577C0.232945 19.7779 -0.000620185 20.6065 1.23678e-06 21.4515V22.2857C1.23678e-06 22.7404 0.180613 23.1764 0.502104 23.4979C0.823595 23.8194 1.25963 24 1.71429 24H22.2857C22.7404 24 23.1764 23.8194 23.4979 23.4979C23.8194 23.1764 24 22.7404 24 22.2857V21.4515C24.0006 20.6065 23.7671 19.7779 23.3252 19.0577C22.8834 18.3375 22.2506 17.7538 21.4971 17.3715C18.569 15.8088 15.3178 14.9481 12 14.8572V14.8572Z'
});

export const Patients: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M7.62596 12.7271C5.08761 12.7271 0 14.0017 0 16.54L0.0133839 18.6071C0.0240624 20.2564 1.36405 21.5877 3.01332 21.5877H15.2846L15.2519 16.54C15.2519 14.0017 10.1643 12.7271 7.62596 12.7271ZM16.3414 12.7271C16.0254 12.7271 15.6659 12.7488 15.2846 12.7815C16.5483 13.6966 17.4308 14.9277 17.4308 16.54L17.4635 21.5877H20.9805C22.645 21.5877 23.9912 20.2327 23.9804 18.5683L23.9673 16.54C23.9673 14.0017 18.8797 12.7271 16.3414 12.7271Z M7.6002 11.1999C10.0302 11.1999 12.0002 9.22996 12.0002 6.7999C12.0002 4.36985 10.0302 2.3999 7.6002 2.3999C5.17014 2.3999 3.2002 4.36985 3.2002 6.7999C3.2002 9.22996 5.17014 11.1999 7.6002 11.1999Z M17.2001 11.2C19.1883 11.2 20.8001 9.58822 20.8001 7.6C20.8001 5.61177 19.1883 4 17.2001 4C15.2119 4 13.6001 5.61177 13.6001 7.6C13.6001 9.58822 15.2119 11.2 17.2001 11.2Z'
  });

export const Settings: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M20.5975 13.175C20.6475 12.8 20.6725 12.4125 20.6725 12C20.6725 11.6 20.6475 11.2 20.585 10.825L23.1225 8.85C23.3475 8.675 23.41 8.3375 23.2725 8.0875L20.8725 3.9375C20.7225 3.6625 20.41 3.575 20.135 3.6625L17.1475 4.8625C16.5225 4.3875 15.86 3.9875 15.1225 3.6875L14.6725 0.5125C14.6225 0.2125 14.3725 0 14.0725 0H9.2725C8.9725 0 8.735 0.2125 8.685 0.5125L8.235 3.6875C7.4975 3.9875 6.8225 4.4 6.21 4.8625L3.2225 3.6625C2.9475 3.5625 2.635 3.6625 2.485 3.9375L0.0975004 8.0875C-0.0524996 8.35 -0.00249971 8.675 0.2475 8.85L2.785 10.825C2.7225 11.2 2.6725 11.6125 2.6725 12C2.6725 12.3875 2.6975 12.8 2.76 13.175L0.2225 15.15C-0.00249969 15.325 -0.0649995 15.6625 0.0725004 15.9125L2.4725 20.0625C2.6225 20.3375 2.935 20.425 3.21 20.3375L6.1975 19.1375C6.8225 19.6125 7.485 20.0125 8.2225 20.3125L8.6725 23.4875C8.735 23.7875 8.9725 24 9.2725 24H14.0725C14.3725 24 14.6225 23.7875 14.66 23.4875L15.11 20.3125C15.8475 20.0125 16.5225 19.6125 17.135 19.1375L20.1225 20.3375C20.3975 20.4375 20.71 20.3375 20.86 20.0625L23.26 15.9125C23.41 15.6375 23.3475 15.325 23.11 15.15L20.5975 13.175ZM11.6725 16.5C9.1975 16.5 7.1725 14.475 7.1725 12C7.1725 9.525 9.1975 7.5 11.6725 7.5C14.1475 7.5 16.1725 9.525 16.1725 12C16.1725 14.475 14.1475 16.5 11.6725 16.5Z'
  });

export const Logout: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M17.7612 7.67372C17.1743 7.22914 16.3493 7.74636 16.4936 8.46829L16.8486 10.2432C16.9476 10.7382 16.569 11.2001 16.0642 11.2001H8C7.55817 11.2001 7.2 11.5583 7.2 12.0001V12.8001C7.2 13.2419 7.55817 13.6001 8 13.6001H16.0642C16.569 13.6001 16.9476 14.062 16.8486 14.557L16.4936 16.3319C16.3493 17.0538 17.1743 17.571 17.7612 17.1265L23.1583 13.0378C23.5808 12.7177 23.5808 12.0825 23.1583 11.7624L17.7612 7.67372ZM2.4 4.8001C2.4 4.35827 2.75817 4.0001 3.2 4.0001H11.2C11.6418 4.0001 12 3.64193 12 3.2001V2.4001C12 1.95827 11.6418 1.6001 11.2 1.6001H2.4C1.08 1.6001 0 2.6801 0 4.0001V20.8001C0 22.1201 1.08 23.2001 2.4 23.2001H11.2C11.6418 23.2001 12 22.8419 12 22.4001V21.6001C12 21.1583 11.6418 20.8001 11.2 20.8001H3.2C2.75817 20.8001 2.4 20.4419 2.4 20.0001V4.8001Z M17.7612 7.67372C17.1743 7.22914 16.3493 7.74636 16.4936 8.46829L16.8486 10.2432C16.9476 10.7382 16.569 11.2001 16.0642 11.2001H8C7.55817 11.2001 7.2 11.5583 7.2 12.0001V12.8001C7.2 13.2419 7.55817 13.6001 8 13.6001H16.0642C16.569 13.6001 16.9476 14.062 16.8486 14.557L16.4936 16.3319C16.3493 17.0538 17.1743 17.571 17.7612 17.1265L23.1583 13.0378C23.5808 12.7177 23.5808 12.0825 23.1583 11.7624L17.7612 7.67372ZM2.4 4.8001C2.4 4.35827 2.75817 4.0001 3.2 4.0001H11.2C11.6418 4.0001 12 3.64193 12 3.2001V2.4001C12 1.95827 11.6418 1.6001 11.2 1.6001H2.4C1.08 1.6001 0 2.6801 0 4.0001V20.8001C0 22.1201 1.08 23.2001 2.4 23.2001H11.2C11.6418 23.2001 12 22.8419 12 22.4001V21.6001C12 21.1583 11.6418 20.8001 11.2 20.8001H3.2C2.75817 20.8001 2.4 20.4419 2.4 20.0001V4.8001Z'
  });

export const Add: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M22.5 10.5H13.5V1.5C13.5 1.10218 13.342 0.720645 13.0607 0.43934C12.7794 0.158036 12.3978 0 12 0C11.6022 0 11.2206 0.158036 10.9393 0.43934C10.658 0.720645 10.5 1.10218 10.5 1.5V10.5H1.5C1.10218 10.5 0.720645 10.658 0.43934 10.9393C0.158036 11.2206 0 11.6022 0 12C0 12.3978 0.158036 12.7794 0.43934 13.0607C0.720645 13.342 1.10218 13.5 1.5 13.5H10.5V22.5C10.5 22.8978 10.658 23.2794 10.9393 23.5607C11.2206 23.842 11.6022 24 12 24C12.3978 24 12.7794 23.842 13.0607 23.5607C13.342 23.2794 13.5 22.8978 13.5 22.5V13.5H22.5C22.8978 13.5 23.2794 13.342 23.5607 13.0607C23.842 12.7794 24 12.3978 24 12C24 11.6022 23.842 11.2206 23.5607 10.9393C23.2794 10.658 22.8978 10.5 22.5 10.5Z'
  });

export const Notification: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M12.2461 24C13.5999 24 14.7076 22.8923 14.7076 21.5385H9.78452C9.78452 22.8923 10.8799 24 12.2461 24ZM19.6307 16.6154V10.4615C19.6307 6.68308 17.6122 3.52 14.0922 2.68308V1.84615C14.0922 0.824615 13.2676 0 12.2461 0C11.2245 0 10.3999 0.824615 10.3999 1.84615V2.68308C6.86759 3.52 4.86144 6.67077 4.86144 10.4615V16.6154L2.3999 19.0769V20.3077H22.0922V19.0769L19.6307 16.6154Z'
  });

export const Info: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M11.7175 24C18.1889 24 23.435 18.6274 23.435 12C23.435 5.37258 18.1889 0 11.7175 0C5.2461 0 0 5.37258 0 12C0 18.6274 5.2461 24 11.7175 24ZM13.4821 17.6222V10.3281C13.4821 9.76223 13.013 9.3091 12.4475 9.32868L9.98272 9.41404C9.46071 9.43212 9.04688 9.86053 9.04688 10.3829V10.8717C9.04688 10.9953 9.12568 11.1051 9.24275 11.1447L10.2181 11.4744V17.6222L9.28552 17.946C9.14265 17.9956 9.04688 18.1302 9.04688 18.2815V18.7152C9.04688 19.2481 9.47886 19.6801 10.0117 19.6801H13.6884C14.2213 19.6801 14.6533 19.2481 14.6533 18.7152V18.2815C14.6533 18.1302 14.5575 17.9956 14.4146 17.946L13.4821 17.6222ZM10.4485 7.62245C10.7941 7.92965 11.2421 8.08325 11.7925 8.08325C12.3173 8.08325 12.7525 7.92965 13.0981 7.62245C13.4565 7.31525 13.6357 6.86085 13.6357 6.25925C13.6357 5.63205 13.4629 5.17125 13.1173 4.87685C12.7717 4.58245 12.3365 4.43525 11.8117 4.43525C11.2741 4.43525 10.8261 4.58885 10.4677 4.89605C10.1221 5.19045 9.94928 5.64485 9.94928 6.25925C9.94928 6.84805 10.1157 7.30245 10.4485 7.62245Z'
  });

export const AssessmentsAlternative: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M15.9718 2.73806C12.3134 -0.91521 6.39709 -0.910162 2.74379 2.73806C-0.914595 6.39644 -0.914595 12.3077 2.74379 15.966C6.39709 19.6143 12.3134 19.6193 15.9718 15.966C19.62 12.3128 19.62 6.39136 15.9718 2.73806ZM13.889 13.8883C11.3838 16.3934 7.32654 16.3885 4.8214 13.8883C2.31136 11.3783 2.31136 7.32583 4.8214 4.81583C7.32654 2.31558 11.3838 2.31068 13.889 4.81583C16.3941 7.32094 16.3941 11.3832 13.889 13.8883Z M23.2846 19.8304L20.4483 16.994L20.6005 16.8418C21.0064 16.4308 21.0064 15.7712 20.6005 15.3652C20.1895 14.9593 19.5299 14.9593 19.1239 15.3652L18.4085 16.0858L17.5713 15.2485C17.2567 15.69 16.9015 16.106 16.5108 16.5018C16.1201 16.8976 15.699 17.2528 15.2575 17.5674L16.0897 18.3995L15.3691 19.12C14.9638 19.5254 14.9633 20.1855 15.3691 20.5915C15.7801 20.9974 16.4398 20.9974 16.8457 20.5915L16.9979 20.4392L19.8343 23.2807C20.7882 24.2296 22.3307 24.2296 23.2846 23.2807C24.2385 22.3268 24.2385 20.7843 23.2846 19.8304V19.8304Z M11.4355 6.74771L11.4355 6.7478L12.1052 8.50077H13.0486C13.314 8.50077 13.5292 8.716 13.5292 8.98133C13.5292 9.24665 13.314 9.46188 13.0486 9.46188H11.7742C11.575 9.46188 11.3963 9.33896 11.3252 9.15278C11.3252 9.15277 11.3252 9.15276 11.3252 9.15275L10.903 8.04717L8.66604 12.0205C8.66604 12.0205 8.66604 12.0205 8.66604 12.0205C8.58059 12.1723 8.42008 12.2652 8.2474 12.2652L11.4355 6.74771ZM11.4355 6.74771C11.3689 6.57394 11.208 6.45381 11.0221 6.43998M11.4355 6.74771L8.21374 12.2639C8.0291 12.2511 7.86843 12.1332 7.80037 11.9612L7.80035 11.9611L6.8135 9.46188H5.65985C5.39453 9.46188 5.1793 9.24665 5.1793 8.98132C5.1793 8.716 5.39453 8.50077 5.65985 8.50077H7.14043C7.33782 8.50077 7.51491 8.62125 7.58747 8.80483L7.58749 8.80486L8.32374 10.6696L10.5679 6.68339L10.568 6.68327C10.6591 6.52197 10.8357 6.42561 11.0221 6.43998M11.0221 6.43998C11.0222 6.43999 11.0222 6.43999 11.0223 6.44L11.0146 6.5397L11.022 6.43997C11.022 6.43998 11.0221 6.43998 11.0221 6.43998Z'
  });

export const Help: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M11.7175 24C18.1889 24 23.435 18.6274 23.435 12C23.435 5.37258 18.1889 0 11.7175 0C5.2461 0 0 5.37258 0 12C0 18.6274 5.2461 24 11.7175 24ZM10.4857 13.191C10.3357 13.7226 10.8077 14.18 11.36 14.18H12.38C12.9323 14.18 13.3391 13.6942 13.6321 13.226C13.6592 13.1827 13.6885 13.1407 13.72 13.1C13.9467 12.7933 14.3067 12.42 14.8 11.98C15.2133 11.6067 15.5467 11.28 15.8 11C16.0667 10.7067 16.2867 10.3533 16.46 9.94C16.6467 9.52667 16.74 9.04667 16.74 8.5C16.74 7.34 16.2733 6.43333 15.34 5.78C14.42 5.12667 13.1933 4.8 11.66 4.8C10.3533 4.8 9.22 5.03333 8.26 5.5C7.64276 5.80427 7.10772 6.19073 6.65486 6.65938C6.25741 7.07069 6.41352 7.72265 6.90683 8.01211L7.63154 8.43735C8.07796 8.6993 8.64025 8.56141 9.03574 8.22748C9.22227 8.06999 9.42369 7.93417 9.64 7.82C10.1333 7.55333 10.6933 7.42 11.32 7.42C11.9733 7.42 12.4933 7.56667 12.88 7.86C13.28 8.14 13.48 8.52667 13.48 9.02C13.48 9.36667 13.3733 9.68 13.16 9.96C12.96 10.2267 12.64 10.56 12.2 10.96C11.6 11.5067 11.14 12.0067 10.82 12.46C10.6736 12.6764 10.5622 12.92 10.4857 13.191ZM10.5 18.68C10.8733 19.0267 11.3333 19.2 11.88 19.2C12.4267 19.2 12.88 19.0267 13.24 18.68C13.6133 18.32 13.8 17.8867 13.8 17.38C13.8 16.8733 13.62 16.4533 13.26 16.12C12.9 15.7733 12.44 15.6 11.88 15.6C11.32 15.6 10.86 15.7733 10.5 16.12C10.14 16.4533 9.96 16.8733 9.96 17.38C9.96 17.8867 10.14 18.32 10.5 18.68Z'
  });

export const Tick: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M8.0893 21.6856C7.87499 21.6849 7.66311 21.6391 7.46683 21.5509C7.27055 21.4628 7.09406 21.3342 6.94833 21.1732L0.423666 13.966C0.139713 13.6559 -0.0123987 13.243 0.000791518 12.8181C0.0139817 12.3932 0.191394 11.991 0.494 11.7001C0.796606 11.4092 1.19962 11.2534 1.61438 11.2669C2.02914 11.2804 2.42167 11.4622 2.70562 11.7722L8.07367 15.5876L21.2184 2.99855C21.3517 2.82837 21.5177 2.68793 21.706 2.58584C21.8943 2.48376 22.101 2.42219 22.3134 2.40492C22.5257 2.38765 22.7393 2.41505 22.941 2.48542C23.1426 2.5558 23.3281 2.66767 23.4861 2.81416C23.644 2.96065 23.771 3.13868 23.8593 3.3373C23.9477 3.53592 23.9954 3.75094 23.9997 3.96916C24.0039 4.18738 23.9646 4.40419 23.8841 4.60627C23.8036 4.80835 23.6836 4.99144 23.5316 5.14428L9.24591 21.1572C9.10156 21.3211 8.92568 21.4526 8.72933 21.5436C8.53298 21.6345 8.3204 21.6829 8.10493 21.6856H8.0893Z'
  });

export const Download: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M6.7844 20.7878H6.14307C5.59078 20.7878 5.1478 21.2475 5.35712 21.7586C5.64015 22.4496 6.17486 23.0857 6.7844 23.0857L18.2738 23.0857C18.8833 23.0857 19.418 22.4496 19.7011 21.7586C19.9104 21.2475 19.4674 20.7878 18.9151 20.7878H18.2738L6.7844 20.7878Z M13.0703 0.847131C15.9481 1.32677 18.4663 3.12544 19.6654 5.88338C22.903 6.84267 24.7016 10.2002 23.7423 13.3179C23.0229 15.836 20.6247 17.6346 17.9866 17.6346H4.79643C2.15839 17.6346 0 15.4763 0 12.8382C0 10.6798 1.55884 8.64133 3.71724 8.16169C4.19688 3.60508 8.39376 0.367488 13.0703 0.847131ZM14.7346 10.0246C15.276 9.91627 15.6639 10.5351 15.3305 10.9752L12.4783 14.7402C12.2382 15.0571 11.7618 15.0571 11.5217 14.7402L8.66951 10.9752C8.33607 10.5351 8.72398 9.91627 9.26543 10.0246L10.4252 10.2565C10.7965 10.3308 11.1429 10.0468 11.1429 9.66817V3.97148C11.1429 3.64011 11.4115 3.37148 11.7429 3.37148H12.2571C12.5885 3.37148 12.8571 3.64011 12.8571 3.97148V9.66817C12.8571 10.0468 13.2035 10.3308 13.5748 10.2565L14.7346 10.0246Z'
  });

export const Zip: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M8.40705 3.29293L8.40675 3.29264C8.29888 3.18591 8.153 3.12495 8 3.12495H2.5C1.07958 3.12495 -0.075 4.27953 -0.075 5.69995V18.7C-0.075 20.1204 1.07958 21.275 2.5 21.275H21.5C22.9204 21.275 24.075 20.1204 24.075 18.7V7.69995C24.075 6.27953 22.9204 5.12495 21.5 5.12495H10.2381L8.40705 3.29293Z M5.58454 15.9828L5.58462 15.9829C5.68696 16.1635 5.87891 16.275 6.08587 16.275H9.08587C9.40329 16.275 9.66087 16.0174 9.66087 15.7C9.66087 15.3825 9.40329 15.125 9.08587 15.125H7.10134L9.57907 10.9957C9.57908 10.9957 9.5791 10.9957 9.57911 10.9957C9.68617 10.8183 9.68836 10.5965 9.58719 10.4171L9.58711 10.417C9.48477 10.2364 9.29283 10.125 9.08587 10.125H6.08587C5.76844 10.125 5.51087 10.3825 5.51087 10.7C5.51087 11.0174 5.76844 11.275 6.08587 11.275H8.0704L5.59266 15.4042C5.59265 15.4042 5.59264 15.4042 5.59262 15.4043C5.48556 15.5816 5.48337 15.8035 5.58454 15.9828ZM14.5109 15.7C14.5109 16.0174 14.7684 16.275 15.0859 16.275C15.4033 16.275 15.6609 16.0174 15.6609 15.7V14.275H16.5859C17.7303 14.275 18.6609 13.3444 18.6609 12.2C18.6609 11.0555 17.7303 10.125 16.5859 10.125H15.0859C14.7684 10.125 14.5109 10.3825 14.5109 10.7V15.7ZM16.5859 13.125H15.6609V11.275H16.5859C17.0964 11.275 17.5109 11.6894 17.5109 12.2C17.5109 12.7105 17.0964 13.125 16.5859 13.125ZM11.5109 15.7C11.5109 16.0174 11.7684 16.275 12.0859 16.275C12.4033 16.275 12.6609 16.0174 12.6609 15.7V10.7C12.6609 10.3825 12.4033 10.125 12.0859 10.125C11.7684 10.125 11.5109 10.3825 11.5109 10.7V15.7Z'
  });

export const Volume: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M20.5365 1.88389C20.3312 1.62117 19.8754 1.4964 19.6169 1.70498C19.3589 1.91308 19.2196 2.37542 19.4244 2.63814C21.5209 5.31945 22.6292 8.55021 22.6292 11.9811C22.6292 15.4124 21.5209 18.6432 19.4244 21.3245C19.2196 21.5872 19.3589 22.0495 19.6169 22.2576C19.7271 22.3462 19.9376 22.3085 20.0675 22.3085C20.2436 22.3085 20.4188 22.2299 20.5365 22.0787C22.802 19.1804 23.9998 15.6888 23.9998 11.9811C23.9998 8.27384 22.802 4.78225 20.5365 1.88389M17.251 4.683C17.0462 4.42452 16.6139 4.336 16.3602 4.54646C16.1078 4.75644 15.9124 5.18442 16.1172 5.44243C17.5739 7.27956 18.3757 9.59693 18.3757 11.9666C18.3757 14.3367 17.5739 16.6536 16.1172 18.4907C15.9124 18.7487 16.0923 19.1767 16.3446 19.3867C16.4539 19.4775 16.6634 19.4728 16.7933 19.4728C16.9652 19.4728 17.1347 19.397 17.251 19.2501C18.8767 17.1988 19.7722 14.6126 19.7722 11.9666C19.7722 9.32103 18.8767 6.73435 17.251 4.683M12.9513 7.36995C12.6943 7.58087 12.5012 7.95235 12.7202 8.19953C13.6491 9.24946 14.1604 10.5871 14.1604 11.9661C14.1604 13.3456 13.6491 14.6832 12.7202 15.7326C12.5012 15.9798 12.6943 16.3518 12.9513 16.5622C13.0662 16.6569 13.2075 16.7035 13.3468 16.7035C13.5196 16.7035 13.691 16.6328 13.812 16.4968C14.9288 15.2345 15.5441 13.6252 15.5441 11.9661C15.5441 10.3069 14.9288 8.69813 13.812 7.43586C13.5935 7.18868 13.2079 7.15808 12.9513 7.36995M9.49595 5.06908L4.59412 8.74503C4.24794 9.00463 3.82691 9.14496 3.39421 9.14496H2C0.89543 9.14496 0 10.0404 0 11.145V13.2656C0 14.3702 0.895431 15.2656 2 15.2656H3.20345C3.6362 15.2656 4.05728 15.406 4.40347 15.6656L9.49595 19.4851C9.70311 19.6405 9.8726 19.5557 9.8726 19.2968V5.25741C9.8726 4.99846 9.70311 4.91371 9.49595 5.06908'
  });

export const Assessments: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M2.25388 3.82028C2.67249 3.40168 3.24024 3.1665 3.83224 3.1665V18.7677C3.83224 20.4245 5.17539 21.7677 6.83224 21.7677H19.4572C19.4572 22.3597 19.2221 22.9275 18.8035 23.3461C18.3849 23.7647 17.8171 23.9998 17.2251 23.9998H3.83224C3.24024 23.9998 2.67249 23.7647 2.25388 23.3461C1.83527 22.9275 1.6001 22.3597 1.6001 21.7677V5.39865C1.6001 4.80665 1.83527 4.23889 2.25388 3.82028Z M19.6211 6.04183H22.6211L17.6211 0.208496V4.04183C17.6211 5.1464 18.5165 6.04183 19.6211 6.04183Z M16.8169 0H7.14425C6.55225 0 5.9845 0.235172 5.56589 0.65378C5.14728 1.07239 4.91211 1.64014 4.91211 2.23214V18.6012C4.91211 19.1932 5.14728 19.7609 5.56589 20.1796C5.9845 20.5982 6.55225 20.8333 7.14425 20.8333H20.5371C21.1291 20.8333 21.6969 20.5982 22.1155 20.1796C22.5341 19.7609 22.7693 19.1932 22.7693 18.6012V6.69643H19.8169C18.16 6.69643 16.8169 5.35328 16.8169 3.69643V0ZM12.6451 7.322C12.5733 7.13346 12.3952 7.00656 12.1935 7.00025C11.9918 6.99393 11.8062 7.10943 11.7227 7.2931L9.90276 11.2969H8.66222C8.38608 11.2969 8.16222 11.5207 8.16222 11.7969C8.16222 12.073 8.38608 12.2969 8.66222 12.2969H10.2247C10.4208 12.2969 10.5988 12.1823 10.6799 12.0038L12.1372 8.79777L14.8356 15.8811C14.9062 16.0665 15.0798 16.1926 15.2779 16.2025C15.4761 16.2124 15.6613 16.1042 15.7501 15.9267L17.565 12.2969H18.8185C19.0946 12.2969 19.3185 12.073 19.3185 11.7969C19.3185 11.5207 19.0946 11.2969 18.8185 11.2969H17.256C17.0666 11.2969 16.8935 11.4039 16.8088 11.5733L15.3648 14.4612L12.6451 7.322Z'
  });

export const Fullscreen: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M1.33333 9.33333C0.59696 9.33333 0 8.73637 0 8V4C0 1.79085 1.79087 0 4 0H8C8.73639 0 9.33333 0.59696 9.33333 1.33333C9.33333 2.06971 8.73639 2.66667 8 2.66667H4C3.26363 2.66667 2.66667 3.26361 2.66667 4V8C2.66667 8.73637 2.06971 9.33333 1.33333 9.33333Z M22.6665 14.6667C23.4029 14.6667 23.9998 15.2637 23.9998 16.0001V20.0001C23.9998 22.2093 22.209 24.0001 19.9998 24.0001H15.9998C15.2634 24.0001 14.6665 23.4031 14.6665 22.6667C14.6665 21.9303 15.2634 21.3334 15.9998 21.3334H19.9998C20.7362 21.3334 21.3332 20.7365 21.3332 20.0001V16.0001C21.3332 15.2637 21.9301 14.6667 22.6665 14.6667Z M14.6665 1.33333C14.6665 0.59696 15.2634 0 15.9998 0H19.9998C22.209 0 23.9998 1.79087 23.9998 4V8C23.9998 8.73637 23.4029 9.33333 22.6665 9.33333C21.9301 9.33333 21.3332 8.73637 21.3332 8V4C21.3332 3.26363 20.7362 2.66667 19.9998 2.66667H15.9998C15.2634 2.66667 14.6665 2.06971 14.6665 1.33333Z M9.33333 22.6667C9.33333 23.4031 8.73637 24.0001 8 24.0001H4C1.79087 24.0001 0 22.2093 0 20.0001V16.0001C0 15.2637 0.59696 14.6667 1.33333 14.6667C2.06972 14.6667 2.66667 15.2637 2.66667 16.0001V20.0001C2.66667 20.7365 3.26363 21.3334 4 21.3334H8C8.73637 21.3334 9.33333 21.9303 9.33333 22.6667Z'
  });

export const Image: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M21.8956 21.5382H2.10438C0.951974 21.5382 0 20.5862 0 19.4338V5.30433C0 4.15193 0.951974 3.19995 2.10438 3.19995H21.8956C23.048 3.19995 24 4.15193 24 5.30433V19.4338C24 20.5862 23.048 21.5382 21.8956 21.5382ZM2.05428 4.55277C1.65345 4.55277 1.35281 4.85339 1.35281 5.25423V19.3837C1.35281 19.7845 1.65345 20.0851 2.05428 20.0851H21.8455C22.2463 20.0851 22.547 19.7845 22.547 19.3837V5.25423C22.547 4.85339 22.2463 4.55277 21.8455 4.55277H2.05428Z M15.5321 11.5674C13.9789 11.5674 12.7764 10.3148 12.7764 8.81165C12.7764 7.30852 14.029 6.05591 15.5321 6.05591C17.0352 6.05591 18.2879 7.30852 18.2879 8.81165C18.2879 10.3148 17.0352 11.5674 15.5321 11.5674Z M19.2905 21.2877L1.10256 20.3358L1.10256 16.5278L0.200684 15.5758L7.03186 9.24562C7.4173 8.88844 8.01344 8.89053 8.39637 9.25039L20.1923 20.3358L19.2905 21.2877Z M22.6417 20.1811H19.0399L15.8332 17.2292L14.9814 16.177L18.4073 13.3856C18.7755 13.0855 19.304 13.0859 19.6718 13.3865L23.6996 16.678L22.6417 20.1811Z'
  });

export const Video: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement(
    'g',
    {},
    React.createElement('path', {
      ...args,
      fill: args.fill ?? themeV1.colors.fills[4],
      d: 'M2.84229 11.0303V17.9636C2.84229 19.9329 4.43727 21.5279 6.40658 21.5279H16.7577C17.4901 21.5279 18.0923 20.9257 18.0923 20.1933V13.2437C18.0923 11.2744 16.4973 9.67944 14.528 9.67944H4.17686C3.44447 9.67944 2.84229 10.2654 2.84229 10.9815V11.0303Z'
    }),
    React.createElement('path', {
      ...args,
      fill: args.fill ?? themeV1.colors.fills[4],
      d: 'M19.2796 18.7447L22.1441 21.2511C22.5998 21.658 23.3159 21.6092 23.7228 21.1535C23.9018 20.9419 23.9994 20.6815 23.9994 20.4211V10.7861C23.9994 10.1676 23.4949 9.67936 22.8927 9.66309C22.616 9.66309 22.3556 9.76074 22.1603 9.93977L19.2796 12.4624C19.0355 12.674 18.9053 12.9832 18.9053 13.3088V17.9147C18.9053 18.2239 19.0518 18.5331 19.2796 18.7447Z'
    }),
    React.createElement('circle', {
      ...args,
      fill: args.fill ?? themeV1.colors.fills[4],
      cx: '3.1579',
      cy: '5.5578',
      r: '3.1579',
    }),
    React.createElement('circle', {
      ...args,
      fill: args.fill ?? themeV1.colors.fills[4],
      cx: '9.94722',
      cy: '5.87349',
      r: '2.52632',
    })
  );

export const Dashboard: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement(
    'g',
    {},
    React.createElement('path', {
      ...args,
      fill: args.fill ?? themeV1.colors.fills[4],
      d: 'M8.18179 0H2.72726C1.22104 0 0 1.22104 0 2.72726V4.90908C0 6.4153 1.22104 7.63634 2.72726 7.63634H8.18179C9.68802 7.63634 10.9091 6.4153 10.9091 4.90908V2.72726C10.9091 1.22104 9.68802 0 8.18179 0Z'
    }),
    React.createElement('path', {
      ...args,
      fill: args.fill ?? themeV1.colors.fills[4],
      d: 'M8.18179 8.72729H2.72726C1.22104 8.72729 0 9.94833 0 11.4546V21.2727C0 22.7789 1.22104 24 2.72726 24H8.18179C9.68802 24 10.9091 22.7789 10.9091 21.2727V11.4546C10.9091 9.94833 9.68802 8.72729 8.18179 8.72729Z'
    }),
    React.createElement('path', {
      ...args,
      fill: args.fill ?? themeV1.colors.fills[4],
      d: 'M21.2736 16.3638H15.8191C14.3128 16.3638 13.0918 17.5848 13.0918 19.091V21.2728C13.0918 22.7791 14.3128 24.0001 15.8191 24.0001H21.2736C22.7798 24.0001 24.0009 22.7791 24.0009 21.2728V19.091C24.0009 17.5848 22.7798 16.3638 21.2736 16.3638Z'
    }),
    React.createElement('path', {
      d: 'M21.2736 0H15.8191C14.3128 0 13.0918 1.22104 13.0918 2.72726V12.5454C13.0918 14.0516 14.3128 15.2727 15.8191 15.2727H21.2736C22.7798 15.2727 24.0009 14.0516 24.0009 12.5454V2.72726C24.0009 1.22104 22.7798 0 21.2736 0Z',
      fill: '#BDC4EC',
    })
  );

export const PhoneSupport: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement(
    'g',
    {},
    React.createElement('path', {
      d: 'M15.541 23.9999C7.55247 23.9999 0 16.4475 0 8.45898C0 7.31198 0.933075 6.37891 2.08007 6.37891H5.35323L7.02602 10.9551L5.64097 13.3815C6.53203 15.2504 8.74957 17.4679 10.6184 18.359L12.6653 16.9946L17.621 18.6467V21.9199C17.621 23.0669 16.688 23.9999 15.541 23.9999Z',
      fill: 'white',
    }),
    React.createElement('path', {
      ...args,
      fill: args.fill ?? themeV1.colors.fills[4],
      d: 'M15.6801 23.6762C7.69163 23.6762 0.13916 16.1237 0.13916 8.13525C0.13916 6.98825 1.07223 6.05518 2.21923 6.05518H5.49239L7.14452 11.0109L5.78013 13.0578C6.67119 14.9266 8.88873 17.1441 10.7576 18.0352L12.8044 16.6709L17.7602 18.323V21.5961C17.7602 22.7431 16.8271 23.6762 15.6801 23.6762V23.6762Z'
    }),
    React.createElement('path', {
      ...args,
      fill: args.fill ?? themeV1.colors.fills[4],
      d: 'M21.506 1.72665C20.5196 0.742721 19.2175 0.138694 17.8292 0.0211061C16.441 -0.0964822 15.0558 0.279921 13.918 1.08393C12.7802 1.88794 11.9629 3.06791 11.6101 4.41572C11.2574 5.76354 11.3919 7.19262 11.99 8.45093C12.0472 8.56153 12.0646 8.68844 12.0393 8.81036L11.6163 10.4902C11.5764 10.6497 11.577 10.8166 11.6181 10.9758C11.6591 11.135 11.7393 11.2813 11.8514 11.4016C11.9634 11.5219 12.1038 11.6123 12.2597 11.6645C12.4155 11.7167 12.582 11.7291 12.7439 11.7006L14.7723 11.3427C14.8716 11.3289 14.9728 11.3429 15.0647 11.3831C15.7858 11.685 16.5598 11.8404 17.3416 11.8402C18.7491 11.8412 20.1108 11.3396 21.1813 10.4257C22.2517 9.5118 22.9607 8.24568 23.1804 6.8554C23.3297 5.92896 23.2557 4.98024 22.9645 4.08818C22.6733 3.19611 22.1732 2.38648 21.506 1.72665V1.72665Z'
    }),
    React.createElement('path', {
      d: 'M17.2929 6.95201C16.9074 6.95201 16.5638 6.61924 16.738 6.27537C16.7589 6.23411 16.7821 6.19463 16.8076 6.15696C16.9555 5.94741 17.1681 5.71629 17.4455 5.4636C17.6489 5.2787 17.7968 5.12462 17.8892 5.00136C17.9878 4.87193 18.0371 4.7271 18.0371 4.56686C18.0371 4.33882 17.9447 4.16009 17.7598 4.03066C17.5811 3.89507 17.3407 3.82728 17.0387 3.82728C16.749 3.82728 16.4902 3.88891 16.2621 4.01217C16.2157 4.03669 16.1707 4.06336 16.1272 4.0922C15.8426 4.28097 15.4725 4.37896 15.1779 4.20611V4.20611C14.8524 4.01512 14.749 3.5814 15.0326 3.33237C15.2073 3.17897 15.4045 3.0481 15.6242 2.93978C16.068 2.72407 16.5919 2.61621 17.1959 2.61621C17.9046 2.61621 18.4716 2.76721 18.8969 3.0692C19.3283 3.3712 19.544 3.7903 19.544 4.32649C19.544 4.57918 19.5009 4.80106 19.4146 4.99212C19.3345 5.18317 19.2328 5.3465 19.1095 5.48209C18.9924 5.61152 18.8383 5.76251 18.6473 5.93508C18.4192 6.13847 18.2528 6.31104 18.1481 6.45279V6.45279C17.968 6.68584 17.7522 6.95201 17.4576 6.95201H17.2929ZM17.2976 9.27244C17.0449 9.27244 16.8322 9.19232 16.6597 9.03208C16.4933 8.86567 16.4101 8.66537 16.4101 8.43117C16.4101 8.19697 16.4933 8.00283 16.6597 7.84875C16.8261 7.68851 17.0387 7.60839 17.2976 7.60839C17.5564 7.60839 17.769 7.68851 17.9354 7.84875C18.1018 8.00283 18.185 8.19697 18.185 8.43117C18.185 8.66537 18.0988 8.86567 17.9262 9.03208C17.7598 9.19232 17.5502 9.27244 17.2976 9.27244Z',
      fill: 'white',
    })
  );

export const Phone: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M20.7852 24C10.101 24 0 13.899 0 3.21484C0 1.6808 1.24794 0.432861 2.78198 0.432861H7.15965L9.39692 6.55322L7.54449 9.7985C8.73623 12.298 11.7021 15.2637 14.2015 16.4555L16.9391 14.6307L23.5672 16.8404V21.218C23.5672 22.7521 22.3192 24 20.7852 24Z M20.9712 23.5672C10.287 23.5672 0.186035 13.4662 0.186035 2.78198C0.186035 1.24794 1.43397 0 2.96802 0H7.34568L9.55532 6.62804L7.73052 9.36563C8.92226 11.8651 11.8881 14.8309 14.3876 16.0227L17.1252 14.1979L23.7532 16.4075V20.7852C23.7532 22.3192 22.5053 23.5672 20.9712 23.5672V23.5672Z'
  });

export const Complete: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M11.7175 24C18.1889 24 23.435 18.6274 23.435 12C23.435 5.37258 18.1889 0 11.7175 0C5.2461 0 0 5.37258 0 12C0 18.6274 5.2461 24 11.7175 24ZM8.24603 17.9162C8.36816 17.971 8.5 17.9996 8.63335 18H8.64307C8.77714 17.9983 8.90941 17.9682 9.03158 17.9116C9.15376 17.855 9.26319 17.7732 9.35301 17.6712L18.2419 7.70761C18.3365 7.61251 18.4111 7.49859 18.4612 7.37285C18.5113 7.24711 18.5358 7.11221 18.5331 6.97643C18.5305 6.84064 18.5008 6.70685 18.4458 6.58327C18.3909 6.45968 18.3118 6.34891 18.2135 6.25776C18.1153 6.16661 17.9999 6.097 17.8744 6.05321C17.7489 6.00942 17.616 5.99238 17.4839 6.00312C17.3517 6.01387 17.2231 6.05218 17.106 6.1157C16.9888 6.17922 16.8855 6.2666 16.8025 6.37249L8.62362 14.2057L5.2835 11.8316C5.10682 11.6387 4.86257 11.5256 4.6045 11.5172C4.34643 11.5088 4.09567 11.6058 3.90738 11.7868C3.71909 11.9678 3.6087 12.2181 3.60049 12.4824C3.59229 12.7468 3.68693 13.0038 3.86361 13.1967L7.9234 17.6812C8.01408 17.7813 8.1239 17.8613 8.24603 17.9162Z'
  });

export const Pending: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M10.2857 1.6001C15.9657 1.6001 20.5714 6.20581 20.5714 11.8858L22.4019 11.2757C22.8739 11.1183 23.261 11.6719 22.9513 12.0613L19.5543 16.3315V16.3315C19.5169 16.4063 19.4146 16.4172 19.3623 16.3521L15.9212 12.0677C15.6088 11.6789 15.996 11.1226 16.4691 11.2803L18.2857 11.8858C18.2857 7.46295 14.7086 3.88581 10.2857 3.88581C5.86286 3.88581 2.28571 7.46295 2.28571 11.8858C2.28571 16.3087 5.86286 19.8858 10.2857 19.8858C12.1307 19.8858 13.8317 19.2541 15.1815 18.1981C15.6177 17.8568 16.2497 17.8498 16.6413 18.2414L16.8472 18.4473C17.2377 18.8378 17.2405 19.4752 16.8144 19.8266C15.039 21.2909 12.7713 22.1715 10.2857 22.1715C4.60571 22.1715 -1.07288e-06 17.5658 -1.07288e-06 11.8858C-1.07288e-06 6.20581 4.60571 1.6001 10.2857 1.6001ZM10.5714 7.31438C11.0448 7.31438 11.4286 7.69814 11.4286 8.17153V12.4593C11.4286 12.8123 11.2425 13.1391 10.9389 13.3193L7.22845 15.5213C6.84652 15.7479 6.35318 15.6224 6.12607 15.2407V15.2407C5.89868 14.8586 6.02428 14.3644 6.40654 14.1373L9.22516 12.4622C9.52842 12.282 9.71429 11.9553 9.71429 11.6026V8.17153C9.71429 7.69814 10.098 7.31438 10.5714 7.31438V7.31438Z'
  });

export const Edit: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M19.4753 0.424571C18.9093 -0.141524 17.9914 -0.141524 17.4253 0.424571L15.3753 2.4746L21.5253 8.6247L23.5754 6.57467C24.1414 6.00857 24.1414 5.09074 23.5754 4.52464L19.4753 0.424571ZM19.4753 10.6748L13.3251 4.52464L0.424571 17.4252C0.152715 17.6972 2.42823e-10 18.0658 2.42823e-10 18.4502V22.5504C-1.44957e-05 23.351 0.648997 24 1.44959 24H5.54965C5.93411 24 6.30281 23.8472 6.57467 23.5754L19.4753 10.6748Z'
  });

export const Permissions: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M21.7382 2.26179C18.7224 -0.75396 13.8157 -0.753913 10.7999 2.26183C8.74 4.32175 8.0245 7.35742 8.91644 10.1116L0.206016 18.822C0.0741564 18.9539 0 19.1324 0 19.3192V23.2969C0 23.6855 0.314485 24 0.703126 24H4.68085C4.8676 24 5.04615 23.9258 5.17796 23.794L6.17223 22.799C6.32397 22.6473 6.39812 22.4344 6.37342 22.2209L6.24981 21.1524L7.73022 21.013C8.06669 20.9814 8.33242 20.7157 8.36402 20.3792L8.50342 18.8988L9.57185 19.0231C9.77027 19.0499 9.9701 18.9833 10.1205 18.8501C10.2701 18.7162 10.356 18.5253 10.356 18.3248V17.0156H11.6414C11.8281 17.0156 12.0067 16.9415 12.1385 16.8096L13.9416 15.0303C16.6951 15.923 19.6783 15.2614 21.7382 13.2001C24.7539 10.1844 24.7539 5.27753 21.7382 2.26179ZM19.749 7.23381C18.9264 8.05642 17.5888 8.05642 16.7662 7.23381C15.9436 6.4112 15.9436 5.07363 16.7662 4.25102C17.5888 3.42841 18.9264 3.42841 19.749 4.25102C20.5716 5.07363 20.5716 6.4112 19.749 7.23381Z'
  });

export const Arrow: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M14.7872 4.79199C14.0536 4.23626 13.0223 4.88278 13.2028 5.7852L13.8179 8.86098C13.9417 9.47977 13.4684 10.0571 12.8373 10.0571H1C0.447716 10.0571 0 10.5048 0 11.0571V12.4857C0 13.038 0.447715 13.4857 1 13.4857H12.8373C13.4684 13.4857 13.9417 14.063 13.8179 14.6818L13.2028 17.7576C13.0223 18.66 14.0536 19.3065 14.7872 18.7508L22.9478 12.5685C23.476 12.1683 23.476 11.3744 22.9478 10.9743L14.7872 4.79199Z M14.7872 4.79199C14.0536 4.23626 13.0223 4.88278 13.2028 5.7852L13.8179 8.86098C13.9417 9.47977 13.4684 10.0571 12.8373 10.0571H1C0.447716 10.0571 0 10.5048 0 11.0571V12.4857C0 13.038 0.447715 13.4857 1 13.4857H12.8373C13.4684 13.4857 13.9417 14.063 13.8179 14.6818L13.2028 17.7576C13.0223 18.66 14.0536 19.3065 14.7872 18.7508L22.9478 12.5685C23.476 12.1683 23.476 11.3744 22.9478 10.9743L14.7872 4.79199Z'
  });

export const Close: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M18.5347 3.51472L12.1708 9.87868L5.8068 3.51472C5.52549 3.23341 5.14396 3.07538 4.74614 3.07538C4.34831 3.07538 3.96678 3.23341 3.68548 3.51472C3.40417 3.79602 3.24614 4.17755 3.24614 4.57538C3.24614 4.9732 3.40417 5.35473 3.68548 5.63604L10.0494 12L3.68548 18.364C3.40417 18.6453 3.24614 19.0268 3.24614 19.4246C3.24614 19.8224 3.40417 20.204 3.68548 20.4853C3.96678 20.7666 4.34831 20.9246 4.74614 20.9246C5.14396 20.9246 5.52549 20.7666 5.8068 20.4853L12.1708 14.1213L18.5347 20.4853C18.816 20.7666 19.1976 20.9246 19.5954 20.9246C19.9932 20.9246 20.3747 20.7666 20.656 20.4853C20.9373 20.204 21.0954 19.8224 21.0954 19.4246C21.0954 19.0268 20.9373 18.6453 20.656 18.364L14.2921 12L20.656 5.63604C20.9373 5.35473 21.0954 4.9732 21.0954 4.57538C21.0954 4.17755 20.9373 3.79602 20.656 3.51472C20.3747 3.23341 19.9932 3.07538 19.5954 3.07538C19.1976 3.07538 18.816 3.23341 18.5347 3.51472Z'
  });

export const Delete: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M3.73324 21.3333C3.73324 22.8067 4.92657 24 6.3999 24H17.0666C18.5399 24 19.7332 22.8067 19.7332 21.3333V7.33333C19.7332 6.22876 18.8378 5.33333 17.7332 5.33333H5.73323C4.62867 5.33333 3.73324 6.22876 3.73324 7.33333V21.3333ZM21.0666 2.66667C21.0666 1.93029 20.4696 1.33333 19.7332 1.33333H17.2283C16.6979 1.33333 16.1892 1.12262 15.8141 0.747547L15.6524 0.585787C15.2773 0.210714 14.7686 0 14.2381 0H9.22833C8.6979 0 8.18919 0.210714 7.81412 0.585786L7.65236 0.747548C7.27728 1.12262 6.76858 1.33333 6.23814 1.33333H3.73324C2.99686 1.33333 2.3999 1.93029 2.3999 2.66667V2.66667C2.3999 3.40305 2.99686 4 3.73324 4H19.7332C20.4696 4 21.0666 3.40305 21.0666 2.66667V2.66667Z'
  });

export const ChevronLeft: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    stroke: args.fill ?? themeV1.colors.fills[4],
    strokeWidth: '3',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    d: 'M20 6L11 15L20 24',
  });

export const ChevronRight: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    stroke: args.fill ?? themeV1.colors.fills[4],
    strokeWidth: '3',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    d: 'M2 2L11 11L2 20',
  });

export const ChevronUp: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    stroke: args.fill ?? themeV1.colors.fills[4],
    strokeWidth: '3',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    d: 'M2 11L11 2L20 11',
  });

export const ChevronDown: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    stroke: args.fill ?? themeV1.colors.fills[4],
    strokeWidth: '3',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    d: 'M2 2L11 11L20 2',
  });

export const Menu: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M21.2892 10.3616H2.59571C1.98652 10.3616 1.49268 10.9965 1.49268 11.7798V11.929C1.49268 12.7123 1.98652 13.3472 2.59571 13.3472H21.2892C21.8984 13.3472 22.3922 12.7123 22.3922 11.929V11.7798C22.3922 10.9965 21.8984 10.3616 21.2892 10.3616Z M22.7333 17.6599H1.26667C0.567106 17.6599 0 18.436 0 19.3932V19.5757C0 20.533 0.567106 21.309 1.26667 21.309H22.7333C23.4329 21.309 24 20.533 24 19.5757V19.3932C24 18.436 23.4329 17.6599 22.7333 17.6599Z M22.7333 2.3999H1.26667C0.567106 2.3999 0 3.17594 0 4.13324V4.31569C0 5.27299 0.567106 6.04903 1.26667 6.04903H22.7333C23.4329 6.04903 24 5.27299 24 4.31569V4.13324C24 3.17594 23.4329 2.3999 22.7333 2.3999Z'
});

export const Quality: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M15.9718 2.73806C12.3134 -0.91521 6.39709 -0.910162 2.74379 2.73806C-0.914595 6.39644 -0.914595 12.3077 2.74379 15.966C6.39709 19.6143 12.3134 19.6193 15.9718 15.966C19.62 12.3128 19.62 6.39136 15.9718 2.73806ZM13.889 13.8883C11.3838 16.3934 7.32654 16.3885 4.8214 13.8883C2.31136 11.3783 2.31136 7.32583 4.8214 4.81583C7.32654 2.31558 11.3838 2.31068 13.889 4.81583C16.3941 7.32094 16.3941 11.3832 13.889 13.8883Z M23.2846 19.8304L20.4483 16.994L20.6005 16.8418C21.0064 16.4308 21.0064 15.7712 20.6005 15.3652C20.1895 14.9593 19.5299 14.9593 19.1239 15.3652L18.4085 16.0858L17.5713 15.2485C17.2567 15.69 16.9015 16.106 16.5108 16.5018C16.1201 16.8976 15.699 17.2528 15.2575 17.5674L16.0897 18.3995L15.3691 19.12C14.9638 19.5254 14.9633 20.1855 15.3691 20.5915C15.7801 20.9974 16.4398 20.9974 16.8457 20.5915L16.9979 20.4392L19.8343 23.2807C20.7882 24.2296 22.3307 24.2296 23.2846 23.2807C24.2385 22.3268 24.2385 20.7843 23.2846 19.8304V19.8304Z M7.81981 12.8001C7.7398 12.7998 7.6607 12.7827 7.58742 12.7498C7.51414 12.7169 7.44825 12.6689 7.39385 12.6088L4.95797 9.91809C4.85196 9.80235 4.79518 9.6482 4.8001 9.48957C4.80502 9.33093 4.87126 9.18079 4.98423 9.07218C5.0972 8.96358 5.24766 8.9054 5.40251 8.91044C5.55735 8.91549 5.70389 8.98334 5.8099 9.09909L7.81398 10.5235L12.7213 5.82359C12.7711 5.76006 12.8331 5.70763 12.9034 5.66952C12.9737 5.6314 13.0508 5.60842 13.1301 5.60197C13.2094 5.59552 13.2891 5.60575 13.3644 5.63203C13.4397 5.6583 13.509 5.70006 13.5679 5.75475C13.6269 5.80944 13.6743 5.87591 13.7073 5.95006C13.7403 6.02421 13.7581 6.10448 13.7597 6.18595C13.7613 6.26742 13.7466 6.34836 13.7165 6.42381C13.6865 6.49925 13.6417 6.5676 13.5849 6.62467L8.25161 12.6028C8.19772 12.664 8.13206 12.7131 8.05875 12.7471C7.98545 12.781 7.90609 12.7991 7.82565 12.8001H7.81981Z'
});

export const Organisation: React.FC<React.SVGProps<SVGSVGElement>> = (args) =>
  React.createElement('path', {
    ...args,
    fill: args.fill ?? themeV1.colors.fills[4],
    d: 'M12.3211 13.4838V10.1307H11.1354V13.4838H3.52014C2.96852 13.4838 2.52135 13.9181 2.52135 14.4539V18.5196H3.8561V15.5421C3.8561 15.2742 3.74616 14.8737 4.3555 14.8737H11.1354V18.3299H12.3211V14.8737H19.4816C19.981 14.8737 19.981 15.2742 19.981 15.5421V18.5196H21.3249V14.4539C21.3249 13.9181 20.8777 13.4838 20.3261 13.4838H12.3211Z M6.45358 1.82731C6.42857 0.857143 6.85714 0 8.30952 0H15.4734C17.1429 0 17.3293 0.857143 17.3293 1.82731V8.73676C17.3293 9.42857 17.1429 10.5641 15.4734 10.5641H8.30952C6.85714 10.5641 6.45358 9.42857 6.45358 8.73676V1.82731Z M0 18.6102C0 18.253 0.298116 17.9634 0.665861 17.9634H5.54884C5.91659 17.9634 6.2147 18.253 6.2147 18.6102V23.3532C6.2147 23.7104 5.91659 24 5.54884 24H0.66586C0.298116 24 0 23.7104 0 23.3532V18.6102Z M8.76347 18.6102C8.76347 18.253 9.06159 17.9634 9.42933 17.9634H14.3123C14.6801 17.9634 14.9782 18.253 14.9782 18.6102V23.3532C14.9782 23.7104 14.6801 24 14.3123 24H9.42933C9.06159 24 8.76347 23.7104 8.76347 23.3532V18.6102Z M17.5216 18.6102C17.5216 18.253 17.8197 17.9634 18.1874 17.9634H23.0704C23.4381 17.9634 23.7363 18.253 23.7363 18.6102V23.3532C23.7363 23.7104 23.4381 24 23.0704 24H18.1874C17.8197 24 17.5216 23.7104 17.5216 23.3532V18.6102Z'
});