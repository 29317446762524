import styled from "@emotion/styled";
import React from "react";
import { BaseText } from "./BaseText";

const BaseTitle = styled(BaseText)(({ size }: { size: "h1" | "h2" }) => {
  const baseStyle = {
    fontWeight: "600",
  };
  switch (size) {
    case "h1":
      return {
        ...baseStyle,
        fontSize: "1.75em",
      };
    case "h2":
      return {
        ...baseStyle,
        fontSize: "1.5em",
      };
  }
});

export const Title = ({
  children,
  size,
}: {
  children: string | React.ReactNode;
  size: "h1" | "h2";
}) => (
  <h3>
    <BaseTitle size={size}>{children}</BaseTitle>
  </h3>
);
