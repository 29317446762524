const TOKEN_KEY = 'token';

// CRUD

export const createToken = (accessToken) => {
  localStorage.setItem(TOKEN_KEY, accessToken);
};

export const deleteToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};
