import styled from '@emotion/styled';
import React from 'react';

interface BoxProps {
  children: string | React.ReactNode | React.ReactNode[];
  // Flex orientation
  orientation?: 'horizontal' | 'vertical';
  // Determines how to space elements along the main axis
  justify?: 'start' | 'end' | 'center' | 'space-between' | 'space-around';
  // Determines how to space elements along the secondary axis
  align?: 'start' | 'end' | 'center' | 'stretch' | 'baseline';
  padding?: string;
  margin?: string;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
}

const flexDirection = {
  horizontal: 'row',
  vertical: 'column',
};

const justifyContent = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  'space-between': 'space-between',
  'space-around': 'space-around',
};

const alignItems = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  stretch: 'stretch',
  baseline: 'baseline',
};

const StyledBox = styled('div')(
  ({
    padding = '0',
    margin = '0',
    maxWidth,
    minWidth,
    maxHeight,
    minHeight,
    orientation = 'horizontal',
    justify = 'start',
    align = 'start',
  }: Omit<BoxProps, 'children'>) => ({
    display: 'flex',
    padding: `${padding}`,
    margin: `${margin}`,
    minWidth: minWidth && `${minWidth}`,
    maxWidth: maxWidth && `${maxWidth}`,
    minHeight: minHeight && `${minHeight}`,
    maxHeight: maxHeight && `${maxHeight}`,
    flexDirection: flexDirection[orientation] as never,
    justifyContent: justifyContent[justify],
    alignItems: alignItems[align]
  })
);

export const Box = ({
  children,
  orientation = 'vertical',
  justify = 'start',
  align: alignItems = 'start',
  padding = '0',
  margin = '0',
  maxWidth,
  minWidth,
  maxHeight,
  minHeight,
  ...props
}: BoxProps) => (
  <StyledBox
    padding={padding}
    margin={margin}
    orientation={orientation}
    justify={justify}
    align={alignItems}
    minWidth={minWidth}
    minHeight={minHeight}
    maxWidth={maxWidth}
    maxHeight={maxHeight}
    {...props}
  >
    {children}
  </StyledBox>
);
