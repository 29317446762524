import styled from '@emotion/styled';
import React from 'react';
import { themeV1 } from '../theme';
import { BaseText } from './BaseText';

const BaseHeading = styled(BaseText)(({ size }: { size: 'h1' | 'h2' | 'h3' | 'h4' }) => {
  switch (size) {
    case 'h1':
      return {
        fontWeight: '400',
        fontSize: '1.25em',
      };
    case 'h2':
      return {
        fontFamily: themeV1.font.fontFamily.inter,
        fontWeight: '600',
        fontSize: '1.375em',
        color: themeV1.colors.base.title,
      };
    case 'h3':
      return {
        fontWeight: '600',
        fontSize: '1.125em',
      };
    case 'h4':
      return {
        fontWeight: '600',
        fontSize: '0.875em',
      };
  }
});

export const Heading = ({
  children,
  size,
}: {
  children: string | React.ReactNode;
  size: 'h1' | 'h2' | 'h3' | 'h4';
}) => (
  <h2>
    <BaseHeading size={size}>{children}</BaseHeading>
  </h2>
);
