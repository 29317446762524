import React from 'react';
import { createRoot } from 'react-dom/client';
import { NavigationDrawer, Icon, themeV1 } from '@mmt_d/mmt-stylekit';
import { deleteToken } from './token';

const navbarRoot = document.getElementById('navbar-app');
const root = createRoot(navbarRoot);
const authContext = JSON.parse(navbarRoot.dataset.authContext);

const contents = [
  {
    key: 1,
    title: 'Assessments',
    hrefLink: '/assessments',
    primaryIcon: <Icon.Medium.Assessments fill={themeV1.colors.fills[0]} />,
    position: 'left',
    isSelected: location.pathname.startsWith('/assessments'),
    isVisible: authContext.is_authenticated,
    type: 'link',
  },
  {
    key: 2,
    title: 'Patients',
    hrefLink: '/kelvin_reach',
    primaryIcon: <Icon.Medium.Patients fill={themeV1.colors.fills[0]} />,
    position: 'left',
    isSelected: false,
    isVisible: authContext.can_see_patients,
    type: 'link',
  },
  {
    key: 3,
    title: 'Quality',
    hrefLink: '/qualityv2',
    primaryIcon: (
      <Icon.Medium.AssessmentsAlternative fill={themeV1.colors.fills[0]} />
    ),
    position: 'left',
    isSelected: false,
    isVisible: authContext.is_authenticated,
    type: 'link',
  },
  {
    key: 4,
    title: 'Admin',
    primaryIcon: <Icon.Medium.Permissions fill={themeV1.colors.fills[0]} />,
    position: 'left',
    isSelected: location.pathname.startsWith('/admin'),
    isVisible:
      authContext.is_authenticated &&
      (authContext.roles.includes('admin') ||
        authContext.roles.includes('internal')),
    type: 'menu',
    subItems: [
      {
        key: 1,
        title: 'Users',
        customComponent: (
          <a className="navlink" href="/admin/users">
            Users
          </a>
        ),
        isSelected: false,
        isVisible: true,
        type: 'custom',
      },
      {
        key: 2,
        title: 'Assessments',
        customComponent: (
          <a className="navlink" href="/admin/assessments">
            Assessments
          </a>
        ),
        isSelected: false,
        isVisible: true,
        type: 'custom',
      },
      {
        key: 3,
        title: 'Media',
        customComponent: (
          <a className="navlink" href="/admin/media">
            Media
          </a>
        ),
        isSelected: false,
        isVisible: true,
        type: 'custom',
      },
      {
        key: 4,
        title: 'Label',
        customComponent: (
          <a className="navlink" href="/admin/label">
            Label
          </a>
        ),
        isSelected: false,
        isVisible: true,
        type: 'custom',
      },
      {
        key: 5,
        title: 'Forms',
        customComponent: (
          <a className="navlink" href="/admin/forms">
            Forms
          </a>
        ),
        isSelected: false,
        isVisible: true,
        type: 'custom',
      },
      {
        key: 6,
        title: 'Form operations',
        customComponent: (
          <a className="navlink" href="/admin/form_update">
            Form operations
          </a>
        ),
        isSelected: false,
        isVisible: true,
        type: 'custom',
      },
    ],
  },
  {
    key: 1,
    title: 'Help',
    customComponent: (
      <div className="custom-nav" data-toggle="modal" data-target="#helpModal">
        <Icon.Medium.Help fill={themeV1.colors.fills[0]} />
        Help
      </div>
    ),
    position: 'right',
    isVisible: authContext.is_authenticated,
    type: 'custom',
  },
  {
    key: 2,
    title: 'Account',
    hrefLink: `/account`,
    primaryIcon: <Icon.Medium.User fill={themeV1.colors.fills[0]} />,
    position: 'right',
    isSelected: location.pathname.includes('account'),
    isVisible: authContext.is_authenticated,
  },
  {
    key: 3,
    title: 'Organisation',
    hrefLink: `/organisation`,
    primaryIcon: <Icon.Medium.Organisation fill={themeV1.colors.fills[0]} />,
    position: 'right',
    isSelected: false,
    isVisible:
      authContext.is_authenticated &&
      (authContext.roles.includes('admin')),
  },
  {
    key: 4,
    title: 'About',
    customComponent: (
      <div
        className="custom-nav"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        <Icon.Medium.Info fill={themeV1.colors.fills[0]} />
        About
      </div>
    ),
    position: 'right',
    isVisible: authContext.is_authenticated,
    type: 'custom',
  },
  {
    key: 5,
    title: 'Sign Out',
    customComponent: (
      <div
        id="nav-logout"
        className="custom-nav"
        onClick={(e) => {
          e.preventDefault();
          deleteToken();
          window.location.href = '/logout';
        }}
      >
        <Icon.Medium.Logout fill={themeV1.colors.fills[0]} />
        Sign out
      </div>
    ),
    position: 'right',
    isSelected: false,
    isVisible: authContext.is_authenticated,
    type: 'custom',
  },
];

const LOGO = (
  <img src="../../static/images/KELVIN-CLOUD_logo-new.svg" alt="Kelvin logo" />
);

root.render(<NavigationDrawer logo={LOGO} navigationContent={contents} />);
