import styled from '@emotion/styled';
import React from 'react';
import { Box } from '../Box';
import { themeV1 } from '../theme';

interface NavigationBarProps {
  children: string | React.ReactNode | React.ReactNode[];
  margin?: string;
}
interface NavigationContent {
  children: string | React.ReactNode | React.ReactNode[];
}

const NavigationBarWrapper = styled(Box)(
  () => ({
    height: '60px',
    backgroundColor: themeV1.colors.menu[0],
    boxShadow: themeV1.shadow.hard,
    position: 'fixed',
    width: '100%',
    zIndex: 99,
    top: 0
  })
);

const ContentWrapper = styled(Box)(
  () => ({
    gap: 40,
    padding: `0 ${themeV1.space[0]}`
  })
);

export const NavigationBar = ({ children, margin }: NavigationBarProps) => (
  <NavigationBarWrapper align='center' justify='space-between' orientation='horizontal' margin={margin}>
    {children}
  </NavigationBarWrapper>
);


const NavigationLeft = ({ children }: NavigationContent) => (
  <ContentWrapper align='center' orientation='horizontal'>
    {children}
  </ContentWrapper>
);

const NavigationRight = ({ children }: NavigationContent) => (
  <ContentWrapper align='center' orientation='horizontal'>
    {children}
  </ContentWrapper>
);


NavigationBar.Left = NavigationLeft;
NavigationBar.Right = NavigationRight;