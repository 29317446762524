import styled from '@emotion/styled';
import React from 'react';
import { themeV1 } from '../theme';

interface NavLinkProps {
  children: string | React.ReactNode | React.ReactNode[];
  href: string;
  // This represents the Anchor tag's target attr, whether to open in new tab.
  isNewPage?: boolean;
  textColor?: string;
  // Nav content with start icon
  startIcon?: React.ReactNode | React.ReactNode[];
  // Nav content with end icon
  endIcon?: React.ReactNode | React.ReactNode[];
  // Gap between two Nav content
  gap?: string;
  // Selected Nav content
  isActive?: boolean;
  padding?: string;
  onClick?: () => void
}

const LinkWrapper = styled('a')(
  ({
    textColor = themeV1.colors.base.white,
    gap = '4px',
    padding = '0'
  }: Omit<NavLinkProps, 'children'>) => ({
    color: textColor,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: `${padding}`,
    gap: `${gap}`,
    '&:hover': {
      color: themeV1.colors.base.body[2]
    },
    'svg:hover path': {
      fill: themeV1.colors.base.body[2]
    }
  })
);

export const NavbarLink = ({
  children,
  href,
  isNewPage = false,
  textColor = themeV1.colors.base.white,
  startIcon,
  endIcon,
  gap = '4px',
  isActive = false,
  padding = '0px',
  onClick,
  ...props
}: NavLinkProps) => (
    <LinkWrapper
    textColor={textColor}
    href={href}
    target={isNewPage ? 'blank' : '_self'}
    gap={gap}
    isActive={isActive}
    padding={padding}
    onClick={onClick}
    {...props}
  >
    {startIcon}
    {children}
    {endIcon}
  </LinkWrapper>
);
