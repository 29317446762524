import {
  Add,
  Arrow,
  Assessments,
  AssessmentsAlternative,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Close,
  Complete,
  Dashboard,
  Delete,
  Download,
  Edit,
  Fullscreen,
  Help,
  Image,
  Info,
  Logout,
  Menu,
  Notification,
  Organisation,
  Patients,
  Pending,
  Permissions,
  Phone,
  PhoneSupport,
  Quality,
  Settings,
  Tick,
  User,
  Video,
  Volume,
  Zip,
} from './iconSvgs';
import { IconTypes } from './types';

const sizes = {
  Small: '16px',
  Medium: '20px',
  Large: '32px',
};

const types: IconTypes = {
  Add,
  Assessments,
  AssessmentsAlternative,
  Download,
  Dashboard,
  Fullscreen,
  Help,
  Image,
  Info,
  Logout,
  Notification,
  Patients,
  Settings,
  Tick,
  User,
  Video,
  Volume,
  Zip,
  PhoneSupport,
  Phone,
  Complete,
  Pending,
  Edit,
  Permissions,
  Arrow,
  Close,
  Delete,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  Menu,
  Quality,
  Organisation
};
type SvgIcon = React.FC<React.SVGProps<SVGSVGElement>>;
type IconWithType = Record<keyof typeof types, SvgIcon>;

const ICON_SIZES = ['Small', 'Medium', 'Large'] as const;
type IconWithSize = Record<(typeof ICON_SIZES)[number], IconWithType>;
const iconWithSize: IconWithSize = {
  Small: types,
  Medium: types,
  Large: types,
};

export class Icon {
  static Small: Record<keyof IconTypes, SvgIcon>;
  static Medium: Record<keyof IconTypes, SvgIcon>;
  static Large: Record<keyof IconTypes, SvgIcon>;
}

ICON_SIZES.forEach((size) => {
  Object.keys(types).forEach((type) => {
    const iconType = type as keyof typeof types;
    if (!Icon?.[size]) {
      Icon[size] = {} as Record<keyof IconTypes, SvgIcon>;
    }
    Icon[size][iconType] = (props) => {
      return (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          style={{ width: sizes[size], height: sizes[size] }}
          {...props}
        >
          {iconWithSize[size][iconType](props)}
        </svg>
      );
    };
  });
});
