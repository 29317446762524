import React from 'react';
import styled from '@emotion/styled';
import { themeV1 } from '../theme';
import { SubContent } from '../NavigationDrawer/NavigationDrawer';
import useCheckMobileScreen from '../CustomHooks/useCheckMobileScreen';

interface MenuProps {
  items: SubContent[] | undefined;
}

interface StyleProps {
  mobileView: boolean;
}

const DropdownMenu = styled('section')(({ mobileView }: StyleProps) => ({
  position: mobileView ? 'initial' : 'absolute',
  top: 55,
  boxShadow: mobileView ? 'none' : themeV1.shadow.regular,
  borderRadius: 12,
  width: mobileView ? '100%' : 150,
  padding: mobileView ? 0 : themeV1.space[0],
  backgroundColor: themeV1.colors.base.white,
  '&:before': !mobileView && {
    content: '""',
    position: 'absolute',
    left: 10,
    borderBottom: `14px solid ${themeV1.colors.base.white}`,
    borderLeft: '16px solid transparent',
    borderRight: '16px solid transparent',
    top: -14
    }
}));

const MenuItem = styled('div')(( { mobileView }: StyleProps) => ({
  cursor: 'pointer',
  color: mobileView ? themeV1.colors.base.white : themeV1.colors.menu[0],
  backgroundColor: mobileView ? themeV1.colors.base.title : themeV1.colors.base.white,
  padding: mobileView ? themeV1.space[1] : `${themeV1.space[0]} 0`,
  '&:hover': {
    color: themeV1.colors.base.body[2]
  },
}));

export const NavbarMenu = ({ items }: MenuProps) => {
  const isMobile = useCheckMobileScreen();
  return (
    <DropdownMenu mobileView={isMobile}>
      {items?.map((item) => {
        return <MenuItem mobileView={isMobile} key={item.key}>{item.customComponent}</MenuItem>;
      })}
    </DropdownMenu>
  );
};
