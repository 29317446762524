import { Theme } from '@emotion/react';

declare module '@emotion/react' {
  export interface Theme {
    colors: {
      primary: {
        menu: string[];
        button: string[];
      };
      base: {
        black: string;
        white: string;
        title: string;
        body: string[];
        highlight: string;
        link: string;
        temporary: string;
      };
      accent: string[];
      menu: string[];
      notifiers: {
        info1: string;
        info2: string;
        info3: string;
        warning: string;
        error: string;
        success: string;
      };
      tags: {
        statusA: string;
        statusTextA: string;
        statusB: string;
        statusTextB: string;
        statusC: string;
        statusTextC: string;
        mainA: string;
        mainB: string;
        mainText: string;
      };
      background: {
        primary: string[];
        secondary: string;
        tertiary: string;
        fourth: string;
      };
      separators: {
        primary: string[];
        secondary: string;
        tertiary: string;
      };
      fills: string[];
      artwork: string[];
    };
    // these are for consistent usage outside of our predefined text based components
    font: {
      fontSize: string[];
      fontFamily: {
        inter: string;
        montserrat: string;
      };
    };
    // consistent margins, paddings, etc.
    space: string[];
    shadow: Record<string, string>;
  }
}

export const themeV1: Theme = {
  colors: {
    primary: {
      menu: ['#75ACFF', 'rgba(117, 172, 255, 0.4)', '#E5ECFC'],
      button: [
        '#3D71C0',
        'rgba(61, 113, 192, 0.65)',
        'rgba(61, 113, 192, 0.15)',
        'rgba(61, 113, 192, 0.15)',
        'rgba(188, 215, 255, 0.3)',
      ],
    },
    accent: ['#FFFF81', '#FFFFD2'],
    menu: [
      '#4D5F8D',
      'rgba(77, 95, 141, 0.65)',
      'rgba(77, 95, 141, 0.15)',
      'rgba(77, 95, 141, 0.10)',
    ],
    notifiers: {
      info1: '#FFFF81',
      info2: '#FFFFD2',
      info3: '#BCD7FF',
      warning: 'rgba(255, 125, 107, 0.6)',
      error: '#FF7D6B',
      success: '#28BE91',
    },
    base: {
      black: '#000000',
      white: '#FFFFFF',
      title: '#283755',
      body: ['#283755', '#6D6875', '#C7C7CA'],
      highlight: '#3D71C0',
      link: '#3D71C0',
      temporary: '#3579F6', // temporary for Kelvin cloud - current
    },
    tags: {
      statusA: 'rgba(40, 190, 145, 0.3)',
      statusTextA: '#1C8968',
      statusB: 'rgba(117, 172, 255, 0.3)',
      statusTextB: '#3D71C0',
      statusC: 'rgba(255, 125, 107, 0.3)',
      statusTextC: '#FF7D6B',
      mainA: '#4D5F8D',
      mainB: '#75ACFF',
      mainText: '#FFFFFF',
    },
    background: {
      primary: ['#F0F0FF', '#E8E8FF', '#E8E8FF'],
      secondary: '#EAEEFB',
      tertiary: '#F2F4FC',
      fourth: '#F2F4FC',
    },
    separators: {
      primary: ['#F0F0FF', '#E8E8FF', '#EDEDFF', '#B5C4DF'],
      secondary: '#BCD7FF',
      tertiary: '#EAEEFB'
    },
    fills: ['#FFFFFF', '#F8FAFF', '#75ACFF', '#3D71C0', '#283755', '#6D6875', '#4D5F8D'],
    artwork: [
      'rgba(181, 155, 255, 0.3)',
      'rgba(40, 190, 145, 0.3)',
      '#FFFFD2',
      'rgba(117, 172, 255, 0.3)',
      'rgba(255, 125, 107, 0.2)',
      '#DBF4EA',
    ],
  },
  font: {
    fontSize: [
      '0.625em', // 10px
      '0.75em', // 12px
      '0.875em', // 14px
      '1em', // 16px
      '1.125em', // 18px
      '1.25em', // 20px
    ],
    fontFamily: {
      inter: 'Inter',
      montserrat: 'Montserrat',
    },
  },
  space: ['0.5em', '1em', '2em', '4em'],
  shadow: {
    hard: '0px 2px 4px rgba(96, 97, 112, 0.16)',
    form: '0px 4px 12px rgba(40, 41, 61, 0.05)',
    formAlternative: '-4px 4px 12px rgba(40, 41, 61, 0.05)',
    medium: '10px 20px 50px rgba(0, 0, 0, 0.05)',
    card: '0px 7px 20px rgba(40, 41, 61, 0.08)',
    regular: '0px 4px 23px rgba(0, 0, 0, 0.12)',
    base: '0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)',
    huge: '1px 5px 22px 4px rgba(0, 0, 0, 0.15)',
    large: '1px 5px 22px 4px rgba(0, 0, 0, 0.15)',
    badge: '0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)',
  },
};
